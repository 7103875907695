<template>
  <v-container class="mt-3 px-sm-10 px-0 elevation-0" fluid>
    <PageHeader
      class="mb-7"
    >
      <template
        v-if="pluginName"
        #header
      >
        <img
          v-if="pluginLogo"
          :src="pluginLogo"
          :alt="`${pluginName} logo`"
          height="32"
        >
        {{ pluginName }} Import
      </template>
      <template
        v-if="pluginName"
        #subheader
      >
        Import a file for the {{ pluginName }} plugin and view your active and previous imports
      </template>
    </PageHeader>

    <v-row v-if="hasWritePermission">
      <v-col>
        <v-row class="pa-0 py-4 flex-nowrap">
          <v-col cols="12">
            <div class="import-headline py-2">
              Import File
            </div>
            <div class="import-subheadline pb-0">
              The file provided must be a {{ expectedFileType }}. To import successfully, please
              <a href="#" @click="downloadTemplate">download our template</a>
            </div>
            <div>
              Note: Action Dates must not be from the future or older than 3 years from today (older than {{ threeYearsAgo }})
            </div>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" class="d-flex justify-start flex-wrap pt-2">
            <v-btn
              class="custom-button custom-button--blue px-11 mr-sm-5 mb-4 mb-sm-0"
              style="min-width: 150px"
              height="34px"
              depressed
              :block="$vuetify.breakpoint.xsOnly"
              @click="selectFile"
            >
              {{ uploadFileName ? `File: ${uploadFileName}` : "Choose File" }}
            </v-btn>

            <input
              ref="fileUpload"
              type="file"
              class="d-none"
              :accept="expectedFileExt"
              @change="onFileSelected"
            >
          </v-col>
        </v-row>

        <v-divider class="mt-5 mb-6" />

        <v-row class="pa-0 flex-wrap">
          <v-col
            cols="12"
            class="d-flex justify-center justify-sm-end align-center flex-wrap"
          >
            <div v-if="uploadPending" class="add-contact-progress pr-sm-7">
              Processing file
            </div>
            <div
              v-else-if="uploadError"
              class="add-contact-progress add-contact-progress--error pr-sm-7"
            >
              The supplied file was invalid
            </div>
            <v-btn
              class="custom-button my-4 px-14"
              :class="uploadPending ? 'custom-button--gray' : 'custom-button--blue'"
              height="34px"
              depressed
              :block="$vuetify.breakpoint.xsOnly"
              @click="onImport"
            >
              Import
            </v-btn>
          </v-col>
        </v-row>
      </v-col>
    </v-row>

    <div v-if="currentImports.length" class="field__header mt-10">
      Active Imports
    </div>
    <ImportsTable
      v-if="currentImports.length"
      :imports="currentImports"
      @canceled="handleCanceledImport"
    />

    <div class="field__header mt-10">
      Previous Imports
    </div>
    <ImportsTable
      :imports="previousImports"
      :for-current-imports="false"
    />
    <PaginationButtons
      :current-page-number="importsPageNumber"
      :current-page-size="importsPageSize"
      :count-total="importsTotal"
      :page-sizes="[25, 50, 100]"
      @next-page="getImportsNextPage"
      @prev-page="getImportsPrevPage"
      @change-page-size="importsChangePageSize"
    />
  </v-container>
</template>

<script>
import datesMixin from "@/utils/datesMixin.js";
import PageHeader from "@/sharedComponents/PageHeader";
import ImportsTable from "@/views/Imports/components/ImportsTable";
import PaginationButtons from "@/sharedComponents/pagination/PaginationButtons";

export default {
  name: "SharedImportPluginsView",
  metaInfo() {
    const that = this;
    return {
      title: `${that.pluginName} Imports`
    }
  },
  components: {
    PageHeader,
    ImportsTable,
    PaginationButtons,
  },
  mixins: [datesMixin],
  props: {
    component_name: {
      default: () => null,
      type: String,
    }
  },
  data() {
    return {
      uploadPending: false,
      uploadError: false,
      uploadFileName: null,
      file: null,
      currentImports: [],
      previousImports: [],
      eventSource: null,
      completedStatuses: ['completed', 'canceled', 'failed'],
      previousStatuses: ['queued', 'preparing', 'analyzing', 'requires action', 'processing', 'finalizing'],
      importType: null,
      pluginName: null,
      pluginLogo: null,
      importsPageNumber: 1,
      importsPageSize: 25,
      importsTotal: 0,
      hasWritePermission: false
    };
  },
  computed: {
    expectedFileType() {
      switch (this.component_name) {
        case 'AddPluginBioPharm':
        case 'AddPluginDoximity':
        case 'AddPluginEpocrates':
        case 'AddPluginMDedge':
        case 'AddPluginMedscape':
        case 'AddPluginMngHealth':
        case 'AddPluginReachMD':
        case 'AddPluginWebMD':
          return 'Pipe-Delimited Text File (.txt)';
        default:
          return 'Comma-Separated Values File (.csv) or Pipe-Delimited Text File (.txt)';
      }
    },
    expectedFileExt() {
      switch (this.component_name) {
        case 'AddPluginBioPharm':
        case 'AddPluginDoximity':
        case 'AddPluginEpocrates':
        case 'AddPluginMDedge':
        case 'AddPluginMedscape':
        case 'AddPluginMngHealth':
        case 'AddPluginReachMD':
        case 'AddPluginWebMD':
          return '.txt';
        default:
          return '.csv,.txt';
      }
    },
    threeYearsAgo() {
      const year = parseInt((new Date()).getFullYear());
      return this.getDate((new Date()).setFullYear(year - 3));
    },
  },
  async created() {
    let activePluginsList = this.$store.getters['user/activePluginsList'];
    let selectedPlugin = activePluginsList?.filter(plugin => plugin?.componentName === this.component_name);
    if (selectedPlugin?.length === 0) {
      this.$notifier.error('The plugin required for the page is not installed for this account.');
      await this.$router.push({
        name: "DashboardView",
        params: { id: this.$store.getters["user/defaultDashboard"] },
      });

      return;
    }

    this.hasWritePermission = this.$store.getters['user/hasWritePermission'];

    this.pluginName = selectedPlugin[0]?.name;
    this.pluginLogo = require(`@/assets/plugins/${selectedPlugin[0]?.image}`);
    this.importType = this.getSpecificPluginImportType(this.component_name);

    await this.getPreviousImports();
    await this.getCurrentImports();
    this.eventSource = await this.$store.dispatch('mercure/import_type_subscribe', this.importType);
    if (!this.eventSource) {
      return;
    }

    this.eventSource.onmessage = (msg) => {
      this.$store.dispatch('mercure/update_import_type_last_event', {
        importType: this.importType,
        lastEventId: msg.lastEventId
      });

      const parsedMessage = JSON.parse(msg.data);

      // only allowed type here
      if (parsedMessage.importType !== this.importType) {
        return;
      }

      const currentImportIndex = this.currentImports.findIndex(elem => elem.id === parsedMessage.id) ?? null;
      if (currentImportIndex === -1) {
        if (this.completedStatuses.includes(parsedMessage.status)) {
          this.getPreviousImports();
          return;
        }

        this.currentImports.unshift(parsedMessage);
        return;
      }

      if (this.completedStatuses.includes(parsedMessage.status)) {
        this.$delete(this.currentImports, currentImportIndex);
        this.getPreviousImports();

        this.$store.commit('snackbar/showMessage', {
          color: "success",
          content: `An import has completed. Please check the section below for the results.`
        })
        return;
      }

      this.$set(this.currentImports, currentImportIndex, parsedMessage);
    };
  },
  beforeDestroy() {
    if (!this.eventSource) {
      return;
    }
    this.$store.dispatch('mercure/unsubscribe', this.eventSource)
  },
  methods: {
    getSpecificPluginImportType(componentName) {
      switch (componentName) {
        case 'AddPluginArrowhead':
          return 'arrowhead';
        case 'AddPluginBioPharm':
          return 'biopharm_action';
        case 'AddPluginBulletinHealthcare':
          return 'bulletin_healthcare_action';
        case 'AddPluginDoximity':
          return 'doximity_action';
        case 'AddPluginEHealthcareSolutions':
          return 'ehealthcare_solutions_action';
        case 'AddPluginEpocrates':
          return 'epocrates_action';
        case 'AddPluginHaymarket':
          return 'haymarket';
        case 'AddPluginHealthcasts':
          return 'healthcasts';
        case 'AddPluginJunGroup':
          return 'jun_group_banner_action';
        case 'AddPluginPulsePointHCP365':
          return 'pulsepoint_hcp365_action';
        case 'AddPluginPulsePointLife':
          return 'pulsepoint_life_banner_action';
        case 'AddPluginMcKesson':
          return 'mckesson_confirmation';
        case 'AddPluginMDedge':
          return 'mdedge_action';
        case 'AddPluginMDLinx':
          return 'mdlinx_action';
        case 'AddPluginMedPageToday':
          return 'medpage_today_banner_action';
        case 'AddPluginMedscape':
          return 'medscape_action';
        case 'AddPluginMngHealth':
          return 'mng_health_action';
        case 'AddPluginOptimizeRx':
          return 'optimizerx_banner_action';
        case 'AddPluginPrescriptionData':
          return 'rx_data';
        case 'AddPluginPvalue':
          return 'pvalue_import';
        case 'AddPluginReachMD':
          return 'reachmd_action';
        case 'AddPluginRepManagement':
          return 'rep_management';
        case 'AddPluginRxNT':
          return 'rxnt_banner_action';
        case 'AddPluginSermo':
          return 'sermo_action';
        case 'AddPluginVeradigm':
          return 'veradigm_banner_action';
        case 'AddPluginVuMedi':
          return 'vumedi_action';
        case 'AddPluginWebMD':
          return 'webmd_action';
        case 'AddPluginDoceree':
          return 'doceree';
        default:
          return null;
      }
    },
    async getPreviousImports() {
      const resp = await this.$rest.imports.get_collection({
        limit: this.importsPageSize,
        page: this.importsPageNumber,
        importType: this.importType,
        status: this.completedStatuses,
        sort: ['updatedAt:desc']
      });
      this.previousImports = resp.data.items;
      this.importsTotal = resp.data.totalCount;
    },
    async getImportsNextPage() {
      this.importsPageNumber += 1;
      await this.getPreviousImports();
    },
    async getImportsPrevPage() {
      this.importsPageNumber -= 1;
      await this.getPreviousImports();
    },
    async importsChangePageSize(size) {
      this.importsPageSize = size;
      this.importsPageNumber = 1;
      await this.getPreviousImports();
    },
    async getCurrentImports() {
      const resp = await this.$rest.imports.get_collection({
        importType: this.importType,
        status: this.previousStatuses,
        sort: ['createdAt:desc']
      });
      this.currentImports = resp.data.items;
    },
    async handleCanceledImport() {
      await this.getCurrentImports();
      await this.getPreviousImports();
    },
    downloadTemplate() {
      window.location.replace(
        process.env.VUE_APP_REST_ADDRESS
          + `imports/${this.$store.getters["user/account"].accountId}/template/${this.importType}`
      );
    },
    selectFile() {
      this.$refs.fileUpload.click();
    },
    /**
     * On File Selected
     * @param {{target: {files: FileList}}} event
     */
    onFileSelected(event) {
      const file = event.target.files[0];
      this.file = file;
      this.uploadFileName = file.name;
    },
    async onImport() {
      if (!this.file) {
        return this.$store.commit('snackbar/showMessage', {
          'content': 'Please select a CSV file before importing.',
          'color': 'error',
        });
      }

      try {
        const formData = new FormData();
        formData.append('file', this.file);
        formData.append('importType', this.importType);

        const { data } = await this.$rest.imports.post_resource(formData);

        this.postUploadStatusHandler(data);
      } catch (e) {
        const errorResponse = e.response?.data ?? null;
        if (errorResponse) {
          const firstError = errorResponse.errors?.[0] ?? null;

          if (firstError) {
            return this.$store.commit('snackbar/showMessage', {
              'content': firstError.error,
              'color': 'error',
            });
          }
        }

        return this.$store.commit('snackbar/showMessage', {
          'content': 'Error while trying to upload the file, please try again.',
          'color': 'error',
        });
      }
    },
    /**
     * After uploaded file, handle some specific cases based on the message
     * @param data
     * @returns {*}
     */
    postUploadStatusHandler(data) {
      if (data.status === 'failed') {
        return this.$store.commit('snackbar/showMessage', {
          'content': 'Failed to process this file. Please try again.',
          'color': 'error',
        });
      }

      this.success_message = 'Your file has been added into the Queue.';
      this.success_dialog = true;

      this.file = null;
      this.uploadFileName = null;
      this.$refs.fileUpload.value = null;

      this.getCurrentImports();
    },
  },
};
</script>

<style lang="scss" scoped>
.mobile-hint {
  font-family: "Open Sans", sans-serif;
  font-size: 14px;
  color: #66788e;
}

.field {
  &__header {
    margin-bottom: 25px;
  }
}
</style>
